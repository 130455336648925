html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  /* font: inherit; */
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html body {
  --antd-wave-shadow-color: transparent !important;
}

body {
  font-family: DM Sans, sans-serif;
  overflow-x: hidden !important;
  overflow-y: overlay;
  color: #090a18;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  user-select: none;
  font-family: DM Sans, sans-serif;
}

body::-webkit-scrollbar {
  -webkit-appearance: none;
  appearance: none;
  width: 6px;
}

body::-webkit-scrollbar-track {
  appearance: none;
  background-color: transparent;
}

body::-webkit-scrollbar-thumb {
  -webkit-appearance: none;
  appearance: none;
  background-color: rgba(0, 0, 0, 0.3);
  transition: 0.3s;
  border-radius: 5px;
}

input {
  border: none;
  outline: none;
  background-color: transparent;
}

/* input[type="date"] {
  color: transparent;
} */

input[type="date"]:focus {
  color: #090a18;
}

a {
  color: #090a18;
}

html {
  overflow: hidden;
  --antd-wave-shadow-color: transparent !important;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.invalid-input,
.invalid-input:focus-within,
.invalid-input:active,
.invalid-input:focus {
  border-color: #f22f46 !important;
}
.ant-drawer-title {
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 33px;
}

.ant-form-item-label {
  text-align: left !important;
}
.ant-input {
  height: 48px !important;
}
.ant-form-item-label > label {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
}
.ant-row.ant-form-item {
  margin-bottom: 12px;
}
.avatar {
  margin-bottom: 42px;
  position: relative;
}
.avatar .box_title {
  margin: 55px 0 20px 0px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.avatar .box_title span {
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 21px;
  position: relative;
  top: -1px;
  display: inline-block;
  margin-left: 4px;
}

.avatar .camera {
  width: 33px;
  height: 33px;
  background-color: #000000;
  border-radius: 50%;
  opacity: 0.29;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 110px;
  bottom: 0;
  transform: translate(-50%, 50%);
  cursor: pointer;
}

.ant-list-item {
  flex-direction: row-reverse;
}
.ant-list-item-action {
  margin-left: 0 !important;
}
.box__dropDown {
  margin-top: 22px;
  padding: 27px 23px;
  background: #f7f7f8;
  border-radius: 4px;
}
.box__dropDown .group__icons {
  display: flex;
  justify-content: space-between;
}
.box__dropDown .group__icons .share {
  display: flex;
  cursor: pointer;
  align-items: center;
}
.box__dropDown .group__icons .share img {
  margin-right: 8px;
}
.box__dropDown .group__icons .share span {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  width: 80px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.box__dropDown .group__icons .edit_menu .delete {
  margin-left: 8px;
}
.box__dropDown .group__icons .edit_menu img {
  cursor: pointer;
}
.add_drop_down {
  text-align: center;
  margin-top: 12px;
  cursor: pointer;
}
.group_button_navigation .ant-btn-primary {
  margin-top: 100px;
}

.group_button_navigation .ant-btn-primary {
  margin-right: 12px;
}

.group_button_navigation .btn--save,
.group_button_navigation .btn--cancel {
  height: 49px;
  padding: 0 40px;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
}

.group_button_navigation .btn--cancel {
  background-color: #fff;
  color: #000000;
  border-color: #d9d9d9 !important;
  box-shadow: none !important;
}
.error_drop_down {
  margin-top: -8px;
  margin-bottom: 12px;
}
.group_button_navigation .btn--cancel:hover {
  background-color: #fff;
  color: #000000;
}
.ant-modal-header {
  border-bottom: none !important;
}
.ant-modal-title {
  font-weight: 700 !important;
  font-size: 25px !important;
}
.ant-select-selection--single {
  height: 48px !important;
}
.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  outline: 0;
  box-shadow: none !important;
}
.ant-input:focus {
  box-shadow: none !important;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  border-bottom: none !important;
}
.ant-btn {
  transition: none !important;
  touch-action: none !important;
  box-shadow: none !important;
}
.ant-btn:hover,
.ant-btn:focus,
.ant-btn:active,
.ant-btn.active {
  box-shadow: none !important;
}
.ant-col {
  overflow: hidden;
}
.ant-modal-close-icon {
  font-size: 20px !important;
}
.ant-input-affix-wrapper:hover .ant-input:not(.ant-input-disabled),
.ant-input:focus,
.ant-input:hover,
.ant-select-selection:hover,
.ant-select-selection:focus,
.ant-select-selection:focus-within {
  border-color: #d9d9d9 !important;
}
/* @media (max-width: 375px) {
  .ant-drawer-wrapper-body {
    width: 320px;
  }
} */
p {
  line-height: normal;
}
